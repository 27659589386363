<template>
  <v-card>
    <v-card-title class="text-h6">
      Mã giảm giá
      <v-spacer></v-spacer>
      <!--<v-btn class="mr-1" color="warning" outlined>
                <v-icon>mdi-download</v-icon>
                {{ $t('labels.sample_file') }}
            </v-btn>
            <v-btn color="primary" outlined class="mr-1" @click="$refs.inputUploadFile.click()">
                <v-icon>mdi-upload</v-icon>
                {{ $t('labels.upload') }}
            </v-btn>
            <input type="file" ref="inputUploadFile" accept=".xlsx" @change="onInputFileChange" class="d-none">-->
      <v-btn
        color="info"
        class="mr-1"
        outlined
        @click="showDialogCreateWithFilter"
      >
        {{ $t("labels.create_with_filter") }}
      </v-btn>
      <v-btn
        color="success"
        outlined
        class="mr-1"
        @click="createAll"
        :disabled="isDisabledAllBtn()"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
      <v-btn color="error" outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 245px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center">
                {{ $t("labels.phone_number") }}
              </th>
              <th class="text-center">
                {{ $t("labels.note") }}
              </th>
              <th class="text-center">
                {{ $t("labels.discount_by") }}
              </th>
              <th class="text-center">
                {{ $t("labels.reduce") }}
              </th>
              <th class="text-center">
                {{ $t("labels.bill_from") }}
              </th>
              <th class="text-center">
                {{ $t("labels.from") }}
              </th>
              <th class="text-center">
                {{ $t("labels.to") }}
              </th>
              <th class="text-center">
                {{ $t("labels.is_discount_sale_product") }}
              </th>
              <th class="text-center" style="width: 80px">
                <v-btn small color="primary" @click="addItem">
                  {{ $t("labels.add") }}
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in items" :key="`re_${key}`">
              <td>
                <select-suggest-buyer
                  v-model="item.phone"
                  :label="$t('labels.phone_number')"
                  class="c-input-small"
                ></select-suggest-buyer>
              </td>
              <td>
                <v-text-field
                  v-model="item.note"
                  :label="$t('labels.note')"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </td>
              <td style="width: 125px">
                <v-autocomplete
                  v-model.number="item.voucher_type"
                  :items="voucherTypeOptions"
                  :label="$t('labels.discount_by')"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <v-text-field
                  v-if="item.voucher_type === 2"
                  v-model.number="item.voucher_value"
                  :label="$t('labels.discount_by_percent')"
                  type="number"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <input-number
                  v-else
                  v-model.number="item.voucher_value"
                  :label="$t('labels.discount_by_price')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  class="c-input-small"
                />
              </td>
              <td>
                <input-number
                  v-model.number="item.discount_from"
                  :label="$t('labels.bill_from')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  class="c-input-small"
                />
              </td>
              <td>
                <date-picker-model
                  v-model="item.start_time"
                  :label="$t('labels.start')"
                  :min-date="currentDate"
                ></date-picker-model>
                <!--<div class="d-flex">
                                <DatePicker :min-date="currentDate" :id-item="key" label="Ngày bắt đầu" name="startDate" @onChange="onDateChange"/>
                                <v-text-field v-model="item.startTime" type="time" label="Giờ bắt đầu" class="c-input-small ml-1" outlined dense hide-details></v-text-field>
                            </div>-->
              </td>
              <td>
                <date-picker-model
                  v-model="item.end_time"
                  :label="$t('labels.end')"
                  :min-date="currentDate"
                ></date-picker-model>
                <!--<div class="d-flex">
                                <DatePicker :min-date="currentDate" :id-item="key" label="Ngày kết thúc" name="endDate" @onChange="onDateChange"/>
                                <v-text-field v-model="item.endTime" type="time" label="Giờ kết thúc" class="c-input-small ml-1" outlined dense hide-details></v-text-field>
                            </div>-->
              </td>
              <td class="text-center vertical-align-middle">
                <div class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="item.is_discount_sale_product"
                    class="mt-0 ml-1"
                    :value="1"
                    hide-details
                  ></v-checkbox>
                </div>
              </td>
              <td class="text-center">
                <v-btn
                  color="primary"
                  x-small
                  style="width: 50px"
                  class="mb-1"
                  @click="create(item, key)"
                  :disabled="isItemDisabled(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  color="error"
                  x-small
                  style="width: 50px"
                  @click="remove(key)"
                >
                  {{ $t("labels.delete") }}
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog v-model="createWithFilterDialog" persistent max-width="800px">
      <VoucherCreateWithFilter
        v-if="createWithFilterDialog"
        @cancel="hideDialogCreateWithFilter"
        @refreshData="refreshData"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { read, utils } from "xlsx";
import moment from "moment";

export default {
  name: "VoucherCreateList",
  components: {
    VoucherCreateWithFilter: () =>
      import("@/components/pos/VoucherCreateWithFilter"),
  },
  props: {},
  data: () => ({
    items: [],
    isLoading: false,
    hasChange: false,
    createWithFilterDialog: false,
  }),
  watch: {},
  computed: {
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    voucherTypeOptions() {
      return [
        {
          text: this.$t("labels.discount_by_percent"),
          color: "primary--text",
          value: 2,
        },
        {
          text: this.$t("labels.discount_by_price"),
          color: "success--text",
          value: 1,
        },
      ];
    },
  },
  mounted() {},
  methods: {
    showDialogCreateWithFilter() {
      this.createWithFilterDialog = true;
    },
    hideDialogCreateWithFilter() {
      this.createWithFilterDialog = false;
    },
    onDateChange(val) {
      const items = [...this.items];
      items[val.id][val.name] = val.value;
      this.items = [...items];
    },
    isItemDisabled(item) {
      return (
        !item.phone ||
        !item.voucher_type ||
        !item.voucher_value ||
        !item.start_time ||
        !item.end_time
      );
    },
    addItem() {
      this.items.unshift({
        phone: null,
        note: null,
        voucher_type: null,
        voucher_value: "",
        discount_from: "",
        is_discount_sale_product: 0,
        start_time: null,
        end_time: null,
      });
    },
    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "ds-khach-hang.xlsx";

      try {
        await this.downloadExcelFile("/buyer-for-voucher-create", {}, filename);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    async onInputFileChange(e) {
      const mappingFields = {
        "Số điện thoại": "phone",
        "Ghi chú": "note",
        "Giảm theo": "voucher_type",
        Giảm: "voucher_value",
        "Áp dụng hóa đơn từ": "discount_from",
        "Áp dụng SP Sale": "is_discount_sale_product",
        "Từ ngày": "start_time",
        "Đến ngày": "end_time",
      };
      const files = e.target.files,
        f = files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const values = utils.sheet_to_json(worksheet);
        const items = [...values].map((v) => {
          const item = {};
          Object.keys(v).forEach((i) => {
            item[mappingFields[i]] = `${v[i]}`.trim();
          });
          return item;
        });
        this.validateData(items);
      };
      await reader.readAsArrayBuffer(f);
    },

    validateData(items) {
      if (!items || items.length === 0) {
        this.$vToastify.error(this.$t("messages.file_is_empty"));
        return false;
      }

      if (items.length > 1000) {
        this.$vToastify.error(this.$t("messages.reserved_goods_max_1000_rows"));
        return false;
      }

      const emptyQuantity = items
        .filter(
          (item) =>
            !item.warehouse_code ||
            !item.customer_goods_barcode ||
            !item.quantity ||
            item.quantity == "0"
        )
        .map((item) => item.customer_goods_barcode);
      if (emptyQuantity && emptyQuantity.length > 0) {
        this.$vToastify.error(
          this.$t("messages.reserved_goods_empty", {
            barcodes: emptyQuantity.join("; "),
          })
        );
        return false;
      }

      const duplicateBarcodes = [];
      const noDuplicateBarcodes = [];
      items.forEach((i) => {
        const nd = [...noDuplicateBarcodes].find(
          (n) => n == i.customer_goods_barcode
        );
        if (!nd) {
          noDuplicateBarcodes.push(i.customer_goods_barcode);
        } else {
          duplicateBarcodes.push(i.customer_goods_barcode);
        }
      });
      if (duplicateBarcodes.length > 0) {
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            barcodes: duplicateBarcodes.join("; "),
          })
        );
        return false;
      }

      this.items = [...items];
    },

    closeDialog() {
      if (this.hasChange) {
        this.$emit("refreshData", true);
      }
      this.$emit("cancel", true);
    },

    refreshData() {
      this.hasChange = true;
    },

    remove(key) {
      this.items = [...this.items].filter((item, index) => index !== key);
    },

    async create(item, index) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      // item = {...item, start_time: `${item.startDate} ${item.startTime}`, end_time: `${item.endDate} ${item.endTime}`}

      try {
        await httpClient.post("/pos-create-voucher", {
          items: [item],
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
        this.hasChange = true;
        this.remove(index);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    isDisabledAllBtn() {
      if (!this.items || this.items.length === 0) {
        return true;
      }
      let isDisabled = false;
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        const itemDisabled = this.isItemDisabled(item);
        if (itemDisabled) {
          isDisabled = true;
        }
      }
      return isDisabled;
    },

    async createAll() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-create-voucher", {
          items: this.items,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
        this.hasChange = true;
        this.items = [];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
